import mitt from 'mitt'

const emitter = mitt()
const EVENTS = {
  // Add new events here
  OPEN_HEADER_NAVIGATION_MOBILE_MENU: 'OPEN_HEADER_NAVIGATION_MOBILE_MENU',
  OPEN_HEADER_CURRENCY_SELECTION: 'OPEN_HEADER_CURRENCY_SELECTION',
}

// Add event bus, as a workaround for complex event handling
export const useEventBus = () => {
  return {
    eventBus: emitter,
    EVENTS,
  }
}
